import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { IPayEnquiryStore } from './store';
import { Grid, Paper, LinearProgress, Button } from '@material-ui/core';
import { PayEnquiryTable } from './components/PayEnquiryTable';
import DateRangePicker from '../components/DateRangePicker';
import { useStyles } from './pay-enquiry.style';
import TeamsMultipleSelect from 'components/TeamsMultipleSelect';
import { PAY_ENQUIRY_KEY } from '@shared/store/AppStore';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';

interface Props {
  teams: string[];
  selectedTeams: string[];
}

interface InjectedProps extends Props {
  payEnquiryStore: IPayEnquiryStore;
};

const PayEnquiryComponent: React.FC<InjectedProps> = observer((props) => {
  const store = (props as InjectedProps).payEnquiryStore;
  const classes = useStyles();

  useEffect(() => {
    store.fetchData();
    store.fetchEnquiries();
  }, []);

  const handleTeamsSelect = (items: string[]) => {
    store.selectTeams(items);
  };
  
  const onExport = () => {
    store.exportPayroll();
  };

  return (
    <Grid container={true}
      direction="column"
      wrap="nowrap"
      className={classes.wrapper}
    >
      <Grid item={true}>
        <Paper className={classes.filter}>
          <Grid container={true} spacing={2}>
            <Grid item xs={2}>
              <TeamsMultipleSelect
                teams={store.teams}
                selectedTeams={store.selectedTeams}
                onSelectedTeamsChanged={handleTeamsSelect}
              />
            </Grid>
            <Grid item={true} lg={4} md={5} xs={4}>
              <DateRangePicker fromDate={store.fromDate} toDate={store.toDate} onChanged={store.selectDateRange} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item={true} className={classes.gridContainer}>
        {store.isLoading && <LinearProgress className={classes.progress} />}
        {!store.isLoading && 
          <>
            <PayEnquiryTable
              payEnquiryGroups={store.filteredEnquiryGroups}
              amountOfRows={store.amountOfRows}
              amountOfSelectedRows={store.amountOfSelectedRows}
            />
            <Grid
              container={true}
              spacing={2}
              className={classes.actions}
            >
              <Grid item xs={2}>
                <Button
                  fullWidth={true}
                  className={classes.action}
                  variant="outlined"
                  color="primary"
                  onClick={onExport}
                >
                  {<SaveAltOutlinedIcon className={classes.icon} />} EXPORT PAYROLL
                </Button>
              </Grid>
            </Grid>
          </>
        }
      </Grid>
    </Grid>
  );
});

export const PayEnquiry = inject(PAY_ENQUIRY_KEY)(PayEnquiryComponent);
