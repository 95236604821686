import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { observer } from 'mobx-react';
import { useStyles } from './totals-row.style';
import { ITechnicianGroup } from 'pay-enquiry/domain';
import clsx from 'clsx';

interface Props {
  technicianGroup: ITechnicianGroup;
  lastTotalRow: boolean;
}

export const TotalRow: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { lastTotalRow, technicianGroup } = props;

  return (
    <TableRow className={clsx(classes.rowCells, {
        [classes.lastTotalRow]: lastTotalRow,
      })}>
      <TableCell colSpan={2} />
      <TableCell align="left">TOTALS:</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.norm)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.th)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.doub)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.dh)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.sl)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.al)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.lsl)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.publicHolidays)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.carersLeave)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.leaveWoPay)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.workersComp)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.mealAllowance)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.callOutAllowance)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.phCallOutAllowance)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.fdo)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.mdo)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.anniversaryLeave)}</TableCell>
      <TableCell align="left">{technicianGroup.getTotalValue(x => x.totalAmount)}</TableCell>
    </TableRow>
  );
});
