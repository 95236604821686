import { Instance, types, flow, getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core';
import { EstimateRequestDetails } from '../domain/RequestDetails';
import { setDebtorIDAPI } from '../domain/CustomerDetails';

export const RequestStore = types
  .model({
    ID: types.optional(types.string, ''),
    prioritiesList: types.array(types.model({
      id: types.string,
      value: types.string
    })),
    areaList: types.array(types.model({
      id: types.string,
      value: types.string
    })),

    isLoading: types.optional(types.boolean, false),
    requestDetails: types.optional(EstimateRequestDetails, {}),
    requestsSummary: types.optional(types.array(EstimateRequestDetails), []),
  })
  .actions((self) => {
    const { api, auth } = getEnv<IStoresEnv>(self);

    const mapData = (y) => ({
      ID: y.id,
      CreatedAt: y.createdAt,
      EstimateNr: y.estimateNr || '',
      Selected: y.selected,
      Priority: y.priority || '',
      Area: y.area || '',
      OriginCallNumber: y.originCallNumber || '',
      DebtorID: y.debtorID || '',
      CustomerName: y.customerName || '',
      ContactName: y.contactName || '',
      ContactPhone: y.contactPhone || '',
      ContactEmail: y.contactEmail || '',
      Location: y.location || '',
      Address: y.address || '',
      Suburb: y.suburb || '',
      Postcode: y.postcode || '',
      State: y.state || '',
      EquipmentID: y.equipmentID || '',
      Make: y.make || '',
      Model: y.model || '',
      SerialNumber: y.serialNumber || '',
      FleetNumber: y.fleetNumber || '',
      EngineHours: y.engineHours || '',
      JobDescription: y.jobDescription || '',
      EstimatedLabourHours: y.estimatedLabourHours || '',
      Status: y.status || '',
      Owner: y.owner || '',
      Branch: y.branch || '',
      AdminFee: y.adminFee || 0,
      PriceLevel: y.priceLevel || '',
      LabourRate: y.labourRate || 0,
    });

    const fetchData = flow(function* fetch() {
      self.isLoading = true;

      const prioritiesResponse = yield api.get(`/api/EstimatePriorityConfig`);
      const data = prioritiesResponse.data.priorities || [];
      self.prioritiesList = data.map((x) => ({ id: x.id, value: x.priority })) || [];

      const areaResponse = yield api.get(`/api/EstimateAreaConfig`);
      const areaTypes = areaResponse.data.areas || [];
      self.areaList = areaTypes.map((x) => ({ id: x.id, value: x.area })) || [];

      self.isLoading = false;

      if (self.prioritiesList.length > 0 && !self.requestDetails.Priority) {
        self.requestDetails.Priority = self.prioritiesList[0].id;
        return
      }
    });

    const getBranchID = flow(function* getBranchID() {
      if (!self.requestDetails.Branch.includes("-")) {
        const branchID = yield api.get(`/api/EstimateBranchConfig/id?office=${auth.officeLocation}`);
        self.requestDetails.Branch = branchID.data.id || '';
        self.requestDetails.ViewAll = branchID.data.viewAll || false;
      }
    });

    const getAllRequests = flow(function* getAllRequests(status: string) {
      self.isLoading = true;
      yield getBranchID();
      try {
        const response = yield api.get(`/api/EstimateRequest/getall?status=${status}&branch=${self.requestDetails.Branch}&viewAll=${self.requestDetails.ViewAll}`);
        self.requestsSummary = response.data.map((x) => mapData(x));
      } catch (error) {
        console.error('Failed to fetch all requests:', error);
        // Handle the error as appropriate for your application
      } finally {
        self.isLoading = false;
      }
    });

    const emailValidate = flow(function* emailValidate(email: string) {
      self.isLoading = true;
      const response = yield api.get(`/api/EstimateEmailValidate/validateEmail?email=${email}`);
      self.isLoading = false;
      return response.data;
    });


   const uploadFileToSharePoint = flow(function* uploadFileToSharePoint(file: File, request) {
      self.isLoading = true;

       const formData = new FormData();
   
      formData.append('file', file);
      formData.append('RequestId', request.ID);
      formData.append('EstimateNumber', request.EstimateNr);
      formData.append('EquipmentID', request.EquipmentID);
      formData.append('Make', request.Make);
      formData.append('Model', request.Model);
      formData.append('SerialNumber', request.SerialNumber);
      formData.append('DebtorID', request.DebtorID);
      formData.append('CustomerName', request.CustomerName);

      try {
    const response = yield api.postFile('/api/sharepoint/upload', { payload: formData });
    console.log('Files uploaded successfully:', response.data);
  } catch (error) {
    console.error('Error uploading files:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    if (error.request) {
      console.error('Request data:', error.request);
    }
    console.error('Error message:', error.message);
  } finally {
    self.isLoading = false;
  }
});



    return {
      selectRequestNumber(requestNumber: string) {
        self.ID = requestNumber;
        self.requestDetails.Location = '';
        self.requestDetails.EquipmentID = '';

        const fetchData = flow(function* fetchData() {

          const requestResponse = yield api.get(`/api/EstimateRequest/id?requestNumber=${self.ID}`);
          if (requestResponse.status !== 200) {
            const errorMessage = `Failed to fetch request: ${requestResponse.error}`;
            window.alert(errorMessage); // Display an alert message with the error message
            throw new Error(errorMessage);
          }

          self.requestDetails.ID = requestResponse.data.id || '';
          self.requestDetails.CreatedAt = requestResponse.data.createdAt || '';
          self.requestDetails.CreatedBy = requestResponse.data.createdBy || '';
          self.requestDetails.EstimateNr = requestResponse.data.estimateNr || '';
          self.requestDetails.Priority = requestResponse.data.priority || '';
          self.requestDetails.Area = requestResponse.data.area || '';
          self.requestDetails.Address = requestResponse.data.address || '';
          self.requestDetails.Branch = requestResponse.data.branch || '';
          self.requestDetails.ContactEmail = requestResponse.data.contactEmail || '';
          self.requestDetails.ContactName = requestResponse.data.contactName || '';
          self.requestDetails.ContactPhone = requestResponse.data.contactPhone || '';
          self.requestDetails.CustomerName = requestResponse.data.customerName || '';
          self.requestDetails.DebtorID = requestResponse.data.debtorID || '';
          setDebtorIDAPI(self.requestDetails, self.requestDetails.DebtorID);
          self.requestDetails.EngineHours = requestResponse.data.engineHours || '';
          self.requestDetails.EquipmentID = requestResponse.data.equipmentID || '';
          self.requestDetails.FleetType = requestResponse.data.fleetType || '';
          self.requestDetails.EstimatedLabourHours = requestResponse.data.estimatedLabourHours || '';
          self.requestDetails.FleetNumber = requestResponse.data.fleetNumber || '';
          self.requestDetails.JobDescription = requestResponse.data.jobDescription || '';
          self.requestDetails.Location = requestResponse.data.location || '';
          self.requestDetails.Suburb = requestResponse.data.suburb || '';
          self.requestDetails.Postcode = requestResponse.data.postcode || '';
          self.requestDetails.State = requestResponse.data.state || '';
          self.requestDetails.Make = requestResponse.data.make || '';
          self.requestDetails.Model = requestResponse.data.model || '';
          self.requestDetails.OriginCallNumber = requestResponse.data.originCallNumber || '';
          self.requestDetails.Owner = requestResponse.data.owner || '';
          self.requestDetails.SerialNumber = requestResponse.data.serialNumber || '';
          self.requestDetails.Status = requestResponse.data.status || '';
          self.requestDetails.AdminFee = requestResponse.data.adminFee || 0;
          self.requestDetails.PriceLevel = requestResponse.data.priceLevel || '';
          self.requestDetails.LabourRate = requestResponse.data.labourRate || 0;
          self.requestDetails.LabourCost = requestResponse.data.labourCost || 0;
        });

        fetchData();
      },

      selectPriority(priority: string) {
        self.requestDetails.Priority = priority;
      },

      selectArea(area: string) {
        self.requestDetails.Area = area;
      },

      save: flow(function* save() {
        self.isLoading = true;
        self.requestDetails.Status = 'Draft';
        self.requestDetails.SubmittedDate = new Date().toISOString();
        yield getBranchID();
        const response = yield api.post(`/api/EstimateRequest/save`,
          { payload: self.requestDetails });
        if (response.status === 200) {
          self.ID = response.data.id || '';
          self.requestDetails.ID = response.data.id || '';
          self.requestDetails.CreatedAt = response.data.createdAt || '';
          self.requestDetails.CreatedBy = response.data.createdBy || '';
          self.isLoading = false;
        }
        else {
          const errorMessage = `Failed to save request: ${response.error}`;
          window.alert(errorMessage);
          console.error('Failed to save request:', response);
        }
      }),

      submit: flow(function* submit() {
        self.isLoading = true;
        self.requestDetails.Status = 'Submitted';
        self.requestDetails.SubmittedDate = new Date().toISOString();
        yield getBranchID();
        const response = yield api.post(`/api/EstimateRequest/save`,
          { payload: self.requestDetails });
        if (response.status === 200) {
          self.ID = response.data.id || '';
          self.requestDetails.ID = response.data.id || '';
          self.isLoading = false;
        }
        else {
          const errorMessage = `Failed to save request: ${response.error}`;
          window.alert(errorMessage);
          console.error('Failed to save request:', response);
        }
      }),

      assignOwner: flow(function* assignOwner(requestId: string) {
        self.isLoading = true;
        const response = yield api.post(`/api/EstimateRequest/assignOwner?requestId=${requestId}&owner=${auth.user?.id || ''}&status=Assigned`,
          { payload: requestId });
        if (response.status === 200) {
          console.log('Owner assigned successfully');
        }
        else {
          console.error('Failed to assign owner:', response);
        }
        yield getAllRequests('All');
        self.isLoading = false;
      }),

      fetchData,
      getAllRequests,
      emailValidate,
      uploadFileToSharePoint,
    };
  })
  .views((self) => {

    return {

      get isReadOnly() {
        return self.requestDetails.Status === 'Submitted';
      }
    };
  });

export type IRequestStore = Instance<typeof RequestStore>;


