import React from 'react';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import Filter5OutlinedIcon from '@material-ui/icons/Filter5Outlined';
import Looks5 from '@material-ui/icons/Looks5';
import ListIcon from '@material-ui/icons/List';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { TimesheetSummary } from 'timesheet-summary';
import { OutstandingApprovals } from 'outstanding-approvals';
import { TimesheetDetails } from 'timesheet-details';
import { SetupPage } from 'setup-page';
import { DefaultPages } from 'setup-page/user-setup/domain/DefaultPages';
import { UserRoles } from "@shared/enums";
import { UnallocatedLostTime } from 'unallocated-lost-time';
import { PayrollAdjustmentEnquiry } from 'payroll-adjustment-enquiry';
import { PayEnquiry } from 'pay-enquiry';
import { CallDetails } from 'call-details';
import { TakeFiveSettings } from 'take-five-settings';
import { TakeFiveSummary } from 'take-five-summary/TakeFiveSummary';
import { TakeFiveDetails } from 'take-five-details/TakeFiveDetails';
import { EstimatingTool } from 'estimating-tool';
import { EstimatingConfig } from 'estimating-config/EstimatingConfig';
import { PriorityConfig } from 'estimating-config/priority-config/PriorityConfig';
import { StatusConfig } from 'estimating-config/status-config/StatusConfig';
import { BranchConfig } from 'estimating-config/branch-config/BranchConfig';
import { AreaConfig } from 'estimating-config/area-config/AreaConfig';
import { EquipmentTypeConfig } from 'estimating-config/equipment-type-config/EquipmentTypeConfig';
import { NewRequestForm } from 'estimating-tool/requests/NewRequestForm';
import { CommonJobsSummary } from 'estimating-tool/common-jobs/CommonJobs';
import { EstimateForm } from 'estimating-tool/estimate/Estimate';
import { useFlags } from 'launchdarkly-react-client-sdk';

type UserRoutes = {
  path: string;
  optionalParams?: string
  title: string;
  icon?: any;
  component?: any;
  defaultPageName?: DefaultPages;
  roles?: string[];
  routeGrp?: string;
}

const routes: UserRoutes[] = [
  {
    path: '/daily-timesheet-summary',
    title: 'Daily Timesheets',
    icon: <HomeOutlinedIcon />,
    component: TimesheetSummary,
    defaultPageName: DefaultPages.Daily,
    roles: [UserRoles.TimesheetEditor]
  },
  {
    path: '/outstanding-approvals',
    title: 'Outstanding Approvals',
    icon: <ListIcon />,
    component: OutstandingApprovals,
    defaultPageName: DefaultPages.OutstandingApprovals,
    roles: [UserRoles.TimesheetApprover]
  },
  {
    path: '/timesheet-details',
    optionalParams: '/:technicianId?/:date?',
    title: 'Timesheet Details',
    component: TimesheetDetails,
    roles: [UserRoles.TimesheetEditor, UserRoles.TimesheetApprover]
  },
  {
    path: '/unallocated-lost-time',
    title: 'Unallocated Lost Time',
    icon: <AccessTimeIcon />,
    component: UnallocatedLostTime,
    roles: [UserRoles.TimesheetEditor, UserRoles.TimesheetApprover]
  },
  {
    path: '/payroll-adjustment-enquiry',
    title: 'Payroll Adjustment',
    icon: <DashboardOutlinedIcon />,
    component: PayrollAdjustmentEnquiry,
    defaultPageName: DefaultPages.PayrollAdjustments,
    roles: [UserRoles.PayrollAdmin]
  },
  {
    path: '/pay-enquiry', 
    title: 'Pay Enquiry', 
    icon: <AttachMoneyIcon />,
    component: PayEnquiry,
    defaultPageName: DefaultPages.PayEnquiry,
    roles: [UserRoles.PayrollAdmin, UserRoles.PayrollEnquiry]
  },
  {
    path: '/call-details', 
    optionalParams: '/:serviceCallNumber?/:appointmentNumber?',
    title: 'Call Details', 
    icon: <DescriptionOutlinedIcon />,
    component: CallDetails,
    defaultPageName: DefaultPages.CallDetails
  },
  {
    path: '/take-five-summary', 
    title: "Completed Take Five's", 
    icon: <Filter5OutlinedIcon />,
    component: TakeFiveSummary, 
    roles: [UserRoles.TakeFiveReader]
  },    
  {
    path: '/take-five-details', 
    optionalParams: '/:technicianId?/:startDate?/:endDate?',
    title: 'Completed Take Five Details', 
    component: TakeFiveDetails,
    roles: [UserRoles.TakeFiveReader]
  },                                  
  {
    path: '/takefive',
    title: 'Take 5 Settings',
    icon: <Looks5/>,
    component: TakeFiveSettings,
    defaultPageName: DefaultPages.TakeFive,
    roles: [UserRoles.SettingsAdmin]
  },
  {
    path: '/setup',
    optionalParams: '/:tab?',
    title: 'Setup',
    icon: <SettingsOutlinedIcon />,
    component: SetupPage,
    defaultPageName: DefaultPages.UserSetup,
    roles: [UserRoles.SettingsAdmin]
  },
  {
    path: '/estimatingConfig',
    optionalParams: '/:tab?',
    title: 'Estimating Config',
    icon: <SettingsOutlinedIcon />,
    component: EstimatingConfig,
    roles: [UserRoles.EstimateEditor],
    routeGrp: 'Estimates'
  },
  {
    path: '/priority', 
    title: 'Priority Config', 
    component: PriorityConfig,
    routeGrp: 'Estimates'
  },
  {
    path: '/status', 
    title: 'Status Config', 
    component: StatusConfig, 
    routeGrp: 'Estimates'
  },
  {
    path: '/branch', 
    title: 'Branch Config', 
    component: BranchConfig, 
    routeGrp: 'Estimates'
  },
  {
    path: '/area', 
    title: 'Area Config', 
    component: AreaConfig, 
    routeGrp: 'Estimates'
  },
  {
    path: '/equipmentType', 
    title: 'Equipment Type Config', 
    component: EquipmentTypeConfig, 
    routeGrp: 'Estimates'
  },
  {
    path: '/estimatingTool',
    optionalParams: '/:tab?',
    title: 'Estimating Tool',
    icon: <LocalAtmIcon />,
    defaultPageName: DefaultPages.EstimatingTool,
    component: EstimatingTool,
    routeGrp: 'Estimates'
  },
  {
    path: '/requests',
    optionalParams: '/:requestNumber?',
    title: 'New Request', 
    component: NewRequestForm, 
    routeGrp: 'Estimates'
  },
  {
    path: '/estimates', 
    optionalParams: '/:estimateNumber?',
    title: 'Estimates', 
    component: EstimateForm, 
    routeGrp: 'Estimates'
  },
  {
    path: '/commonJobs', 
    title: 'Common Jobs', 
    component: CommonJobsSummary, 
  }
];
export const getAvaliableRoutes = (roles: string[]) => {
  const flags = useFlags();

 if(!flags.estimatesEnabled)
   return routes.filter((t) => (!t.roles || t.roles.some((r) => roles.includes(r))) && t.routeGrp != 'Estimates');
 else
   return routes.filter((t) => !t.roles || t.roles.some((r) => roles.includes(r)));
};
