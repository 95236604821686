export const enum DefaultPages {
    Daily = 'Daily Timesheet Summary',
    OutstandingApprovals = 'Outstanding Approvals',
    UserSetup = 'User Setup',
    PayEnquiry = 'Pay Enquiry',
    PayrollAdjustments = 'Payroll Adjustments',
    CallDetails = 'Call Details',
    TakeFive = 'Take Five',
    TakeFiveSummary  = 'Take Five Summary',
    EstimatingTool = 'Estimating Tool'
}

export const DefaultPage = DefaultPages.Daily;
