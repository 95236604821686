import { TimesheetSummaryStore } from '../timesheet-summary/store/TimesheetSummaryStore';
import { TimesheetDetailsStore } from '../timesheet-details/stores/TimesheetDetailsStore';
import { UserSetupStore } from './../setup-page/user-setup/store/UserSetupStore';
import { LocalFindSetupStore } from './../setup-page/local-find-setup/store/LocalFindSetupStore';
import { OutstandingApprovalsStore } from '../outstanding-approvals/store/OutstandingApprovalsStore';
import { UnallocatedLostTimeStore } from '../unallocated-lost-time/store/UnallocatedLostTimeStore';
import { ApiStore, GraphApiStore } from 'api';
import { getStorage } from '@utils/getStorage';
import { IStoresEnv, IApiStoreEnv, INotificationEnv } from './storesEnv';
import { AppStore, APP_INJECTION_KEY, PAYROLL_INJECTION_KEY, PAY_ENQUIRY_KEY, CALL_DETAILS_KEY } from '@shared/store';
import { AuthStore } from 'auth';
import { PayrollAdjustmentEnquiryStore } from 'payroll-adjustment-enquiry/store/PayrollAdjustmentEnquiryStore';
import { PayEnquiryStore } from 'pay-enquiry/store/PayEnquiryStore';
import { SnackMessagesStore } from '@shared/store/snackMessages';
import { CallDetailsStore } from 'call-details/store/CallDetailsStore';
import { TakeFiveStore } from 'take-five-settings/stores/TakeFiveStore';
import { TakeFiveSummaryStore } from 'take-five-summary/store/TakeFiveSummaryStore';
import { TakeFiveDetailsStore } from 'take-five-details/store/TakeFiveDetailsStore';
import { EstimatingToolStore } from 'estimating-tool/store/EstimatingToolStore';
import { RequestsSummaryStore } from 'estimating-tool/requests/store/RequestsSummaryStore';
import { CommonJobsSummaryStore } from 'estimating-tool/common-jobs/store/CommonJobsSummaryStore';
import { AreaConfigStore } from 'estimating-config/area-config/store/AreaConfigStore';
import { BranchConfigStore } from 'estimating-config/branch-config/store/BranchConfigStore';
import { EquipmentTypeConfigStore } from 'estimating-config/equipment-type-config/store/EquipmentTypeConfigStore';
import { PriorityConfigStore } from 'estimating-config/priority-config/store/PriorityConfigStore';
import { StatusConfigStore } from 'estimating-config/status-config/store/StatusConfigStore';
import { RequestStore } from 'estimating-tool/requests/store/RequestStore';
import { EstimatesListStore } from 'estimating-tool/estimate/store/EstimatesListStore';
import { EstimateDetailsStore } from 'estimating-tool/estimate/store/EstimateDetailsStore';
import { TaskStore } from 'estimating-tool/estimate/components/tasks/store/TaskStore';
import { PartStore } from 'estimating-tool/estimate/components/parts/store/PartStore';




export const STORE_KEYS = {
  TimesheetSummaryStore: 'TimesheetSummaryStore',
  OutstandingApprovalsStore: 'OutstandingApprovalsStore',
  UnallocatedLostTimeStore: 'UnallocatedLostTimeStore',
  TimesheetDetailsStore: 'TimesheetDetailsStore',
  UserSetupStore: 'UserSetupStore',
  LocalFindSetupStore: 'LocalFindSetupStore',
  TakeFiveStore: 'TakeFiveStore',
  TakeFiveSummaryStore: 'TakeFiveSummaryStore',
  TakeFiveDetailsStore: 'TakeFiveDetailsStore',
  EstimatingToolStore: 'EstimatingToolStore',
  RequestsSummaryStore: 'RequestsSummaryStore',
  CommonJobsSummaryStore: 'CommonJobsSummaryStore',
  AreaConfigStore: 'AreaConfigStore',
  BranchConfigStore: 'BranchConfigStore',
  EquipmentTypeConfigStore: 'EquipmentTypeConfigStore', 
  PriorityConfigStore: 'PriorityConfigStore',
  StatusConfigStore: 'StatusConfigStore',
  RequestStore: 'RequestStore',
  EstimatesListStore: 'EstimatesListStore',
  EstimateDetailsStore: 'EstimateDetailsStore',
  TaskStore: 'TaskStore',
  PartStore: 'PartStore',
};

export const createStores = () => {

  const snackMessenger = SnackMessagesStore.create();
  const notification: INotificationEnv = {
    snackMessenger,
  };
  const api = ApiStore.create({}, notification);
  const graphapi = GraphApiStore.create({}, notification);

  const apiEnv: IApiStoreEnv = {
    api,
    graphapi,
    snackMessenger,
  };
  const auth = AuthStore.create({}, apiEnv);

  const env: IStoresEnv = {
    api,
    localStorage: getStorage(),
    auth,
    snackMessenger,
  };

  return {
    [APP_INJECTION_KEY]: AppStore.create({}, env),
    [PAYROLL_INJECTION_KEY]: PayrollAdjustmentEnquiryStore.create({}, env),
    [PAY_ENQUIRY_KEY]: PayEnquiryStore.create({}, env),
    [CALL_DETAILS_KEY]: CallDetailsStore.create({}, env),
    [STORE_KEYS.TimesheetSummaryStore]: TimesheetSummaryStore.create({}, env),
    [STORE_KEYS.OutstandingApprovalsStore]: OutstandingApprovalsStore.create({}, env),
    [STORE_KEYS.UnallocatedLostTimeStore]: UnallocatedLostTimeStore.create({}, env),
    [STORE_KEYS.TimesheetDetailsStore]: TimesheetDetailsStore.create({}, env),
    [STORE_KEYS.UserSetupStore]: UserSetupStore.create({}, env),
    [STORE_KEYS.LocalFindSetupStore]: LocalFindSetupStore.create({}, env),
    [STORE_KEYS.TakeFiveStore]: TakeFiveStore.create({}, env),
    [STORE_KEYS.TakeFiveSummaryStore]: TakeFiveSummaryStore.create({}, env),
    [STORE_KEYS.TakeFiveDetailsStore]: TakeFiveDetailsStore.create({}, env),
    [STORE_KEYS.EstimatingToolStore]: EstimatingToolStore.create({}, env),
    [STORE_KEYS.RequestsSummaryStore]: RequestsSummaryStore.create({}, env),
    [STORE_KEYS.CommonJobsSummaryStore]: CommonJobsSummaryStore.create({}, env),
    [STORE_KEYS.AreaConfigStore]: AreaConfigStore.create({}, env),
    [STORE_KEYS.BranchConfigStore]: BranchConfigStore.create({}, env),
    [STORE_KEYS.EquipmentTypeConfigStore]: EquipmentTypeConfigStore.create({}, env),
    [STORE_KEYS.PriorityConfigStore]: PriorityConfigStore.create({}, env),
    [STORE_KEYS.StatusConfigStore]: StatusConfigStore.create({}, env),
    [STORE_KEYS.RequestStore]: RequestStore.create({}, env),
    [STORE_KEYS.EstimatesListStore]: EstimatesListStore.create({}, env),
    [STORE_KEYS.EstimateDetailsStore]: EstimateDetailsStore.create({}, env),
    [STORE_KEYS.TaskStore]: TaskStore.create({}, env),
    [STORE_KEYS.PartStore]: PartStore.create({}, env),
  };
};
