import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ITimesheetDetailsStore } from './stores/TimesheetDetailsStore';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import { STORE_KEYS } from '../@core';
import { Totals } from './components/Total';
import { GpAdjustments } from './components/gp-adjustments/GpAdjustments';
import { PayrollAdjustments } from './components/payroll-adjustments/PayrollAdjustments';
import { Allocations } from './components/allocations/Allocations';
import { grey } from '@material-ui/core/colors';
import { Grid,  LinearProgress, Typography } from '@material-ui/core';
import { Actions } from './components/Actions';
import { TimesheetLines } from './components/timesheet-lines/TimesheetLines';
import { TimeSheetHeader } from './components/TimeSheetHeader';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Prompt } from 'react-router-dom';
import { APP_INJECTION_KEY, IAppStore } from '@shared/store';
import { RejectDialog } from './components/RejectDialog';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ErrorList } from './components/ErrorList';
import { useIdle } from '@utils';
import { useBeforeunload } from 'react-beforeunload';
import TimesheetPromt from './components/TimesheetPromt';
import { RejectionReasonPanel } from './components/RejectionReasonPanel';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    totals: {
      backgroundColor: grey['300'],
      padding: theme.spacing(1.5)
    },
    progress: {
      width: '100%'
    }
  })
);

interface PathParams {
  technicianId: string;
  date: string;
}

interface Props extends RouteComponentProps<PathParams> {
  TimesheetDetailsStore: ITimesheetDetailsStore;
  AppStore: IAppStore;
}


const TimesheetDetailsComponent: React.FC<Props> = observer((props) => {
  const flags = useFlags();
  const [hasPermissionToAddNonLeave, sethasPermissionToAddNonLeave]  = React.useState(false)

  const store = props.TimesheetDetailsStore;
  const user = props.AppStore.user;

  const classes = useStyles();
  const params = useParams<PathParams>();
  const technicianId = params.technicianId;
  const date = new Date(params.date);
  const isIdle = useIdle({ timeToIdle: Number(TIMESHEET_LOCK_LEASE_DURATION_SECONDS) * 1000, inactivityEvents: [] });
  if (isIdle) {
    store.releaseLock();
    store.setSessionExpired();
  } else {
    store.extendLock();
  }

  const isTimeSheetCreator = (): boolean => {
    const team = store.timeSheetDetails.header.team.trim();
    const hasPermission = user?.assignedTeams.some((t) => t.teamName === team && t.isTimesheetCreator);
    return !!hasPermission;
  };

  const isTimeSheetEditor = (): boolean => {
    const team = store.timeSheetDetails.header.team.trim();
    const hasPermission = user?.assignedTeams.some((t) => t.teamName === team && t.isTimesheetEditor);
    return !!hasPermission;
  };

  const isTimesheetApprover = (): boolean => {
    const team = store.timeSheetDetails.header.team.trim();
    const hasPermission = user?.assignedTeams.some((t) => t.teamName === team && t.isTimesheetApprover);
    return !!hasPermission;
  };

  const disableAllowances  = (): boolean => {
    return store.disableAllowances || !isEditor;
  }

  const isEditor = isTimeSheetEditor();
  const isApprover = isTimesheetApprover();
  const isAllowancesDisabled = disableAllowances();
  const [isOpenRejectDialog, setOpenRejectDialog] = React.useState(false);
  const handleRejectRequest = () => {
    setOpenRejectDialog(true);
  };
  const handleRejectDialogClose = () => {
    setOpenRejectDialog(false);
  };
  const handleConfirmReject = (reason: string) => {
    setOpenRejectDialog(false);
    store.onReject(reason);
  };

  const overtimeHoursWarning =
    Object.values(store.timeSheetDetails.timelines.validateOvertimeHours(store.timeSheetDetails.header.shift))
      .filter((e) => !!e)
      .join('; ');

  const [isOpenSaveConfirmDialog, setOpenSaveConfirmDialog] = React.useState(false);
  const handleSave = () => {
    if (!!overtimeHoursWarning) {
      setOpenSaveConfirmDialog(true);
      return;
    }

    store.onSave();
  };
  const onSaveConfirmDialogClose = (isConfirmed) => {
    setOpenSaveConfirmDialog(false);
    if (!isConfirmed) {
      return;
    }

    store.onSave();
  };

  const [isOpenSubmitConfirmDialog, setOpenSubmitConfirmDialog] = React.useState(false);
  const handleSubmit = () => {
    if (!!overtimeHoursWarning) {
      setOpenSubmitConfirmDialog(true);
      return;
    }

    store.onSubmit();
  };
  const onSubmitConfirmDialogClose = (isConfirmed) => {
    setOpenSubmitConfirmDialog(false);
    if (!isConfirmed) {
      return;
    }

    store.onSubmit();
  };

  const isCreator = isTimeSheetCreator();
  useEffect(() => {
    if(!flags.timesheetCreationSecurity)
      return

    sethasPermissionToAddNonLeave(isCreator)

  }, [isCreator]);

  const { timeSheetDetails } = store;

  useEffect(() => {
    store.fetchData(technicianId, date);

    return function cleanup() {
      if (!store.isLocked) {
        store.releaseLock();
      }
      store.resetStore();
    };
  }, []);
  
  
  useBeforeunload(() => {
    const url = `${API_URL}/api/LockTimesheetHeader/unlock?id=${store.timeSheetDetails.header.id}`;
    navigator.sendBeacon(url);
    return true;
  });

  const prohibitAccess = () => {
    return (
      <Typography variant="h5">You have neither Timesheet Editor nor Timesheet Approver permissions </Typography>
    );
  };

  const getContent = () => {
    if (!!store.timeSheetDetails.header.id &&
      !store.isLoading &&
      !isEditor &&
      !isApprover) {
      return prohibitAccess();
    }

    if (store.dataLoadingError) {
      return (
        <Typography variant="h4" component="h2">
          {store.dataLoadingError}
        </Typography>
      );
      }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Prompt
          when={store.hasAnyChanges && !store.isSessionExpired && !store.isLocked}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <RejectionReasonPanel rejectionReason={timeSheetDetails.header.rejectionReason} />
          </Grid>
          <Grid item={true} xs={12}>
            <ErrorList errors={store.validationErrors} />
          </Grid>
          <Grid item={true} xs={12}>
            <TimeSheetHeader
              disabled={isAllowancesDisabled}
              header={timeSheetDetails.header}
              onChange={timeSheetDetails.setTimeSheetHeader}
            />
          </Grid>
          {store.isLoading && <Grid item={true} xs={12}><LinearProgress className={classes.progress} /> </Grid>}
          {!store.isLoading && <>
            <Grid item={true} xs={12}>
              <TimesheetLines
                disabled={!isEditor}
                canAddNonLeave={hasPermissionToAddNonLeave}
                lostTimeTypes={store.lostTimeTypes.slice()}
                recoverableLostTimeTypes={store.recoverableLostTimeTypes.slice()}
                shift={timeSheetDetails.header.shift}
                date={timeSheetDetails.header.getDate}
                timelinesModel={timeSheetDetails.timelines}
                getAllocTime={timeSheetDetails.getAllocTime}
                onDelete={timeSheetDetails.deleteTimeLine}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <GpAdjustments
                date={timeSheetDetails.header.getDate}
                disabled={!isEditor}
                adjustmentsModel={timeSheetDetails.gpAdjustments}
                allocations={timeSheetDetails.allocations}
                maximumCallAdjustmentAllocations={timeSheetDetails.maximumCallAdjustmentAllocations}
                validate={timeSheetDetails.validateGAdjustments}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <PayrollAdjustments
                disabled={!isEditor}
                adjustmentsModel={timeSheetDetails.payrollAdjustments}
                validate={store.timeSheetDetails.validatePayrollAdjustments}
            />
            </Grid>
            <Grid item={true} xs={12}>
              <Allocations
                date={timeSheetDetails.header.getDate}
                disabled={!isEditor}
                allocations={timeSheetDetails.allocations}
                adjustments={timeSheetDetails.gpAdjustments.adjustments}
                onAdd={timeSheetDetails.addAllocation}
                onDelete={timeSheetDetails.deleteAllocation}
                validate={timeSheetDetails.validateAllocations}
                recoverableLostTimeLines={timeSheetDetails.recoverableLostTimeLines}
                maximumCallAdjustmentAllocations={timeSheetDetails.maximumCallAdjustmentAllocations}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Grid container={true} justifyContent="flex-end">
                <Grid item={true} xs={6} className={classes.totals}>
                  <Totals hours={timeSheetDetails.totalHours} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <Grid container={true} justifyContent="flex-end">
                <Grid item={true} xs={12}>
                  <Actions
                    disableSave={!store.canSave}
                    disableSubmit={!store.canSubmit}
                    disableApprove={!store.canApprove}
                    disableReject={!store.canReject}
                    isTimeSheetEditor={isEditor}
                    isTimeSheetApprover={isApprover}
                    onSave={handleSave}
                    onSubmit={handleSubmit}
                    onApprove={store.onApprove}
                    onReject={handleRejectRequest}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
          }
        </Grid>

        <ConfirmationDialog
          title="Warning"
          confirmButtonTitle="Ignore"
          description={overtimeHoursWarning}
          open={isOpenSaveConfirmDialog}
          onClose={onSaveConfirmDialogClose}
        />

        <ConfirmationDialog
          title="Warning"
          confirmButtonTitle="Ignore"
          description={overtimeHoursWarning}
          open={isOpenSubmitConfirmDialog}
          onClose={onSubmitConfirmDialogClose}
        />

        <RejectDialog
          isOpen={isOpenRejectDialog}
          onClose={handleRejectDialogClose}
          onConfirmReject={handleConfirmReject}
        />

        <TimesheetPromt
          open={store.isSessionExpired}
          title="Edit session has been expired"
        />

        <TimesheetPromt
          open={store.isLocked && !store.isLoading}
          title="Timesheet is editing by another user."
        />

      </MuiPickersUtilsProvider>
    );
  };

  return getContent();
});

export const TimesheetDetails = inject(STORE_KEYS.TimesheetDetailsStore, APP_INJECTION_KEY)(TimesheetDetailsComponent);
