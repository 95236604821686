import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import React, { useState } from 'react';
import { PayrollAdjustmentEnquiryModel } from '../domain/PayrollAdjustmentEnquiry';
import EnhancedTableHead, { HeadCell } from '../../components/enhanced-table/EnhancedTableHead';
import { observer } from 'mobx-react-lite';
import { SortOrder, sort } from '@utils';
import { useStyles } from './table.style';
import { IPayrollEnquiryGroup } from 'payroll-adjustment-enquiry/domain/PayrollEnquiryGroup';
import { PayrollEnquiryGroup } from './PayrollEnquiryGroup';
import { isEmpty } from 'lodash';

interface Props {
  enquiryGroups: IPayrollEnquiryGroup[];
  amountOfRows: number;
  amountOfSelectedRows: number;
}

const headCells: Array<HeadCell<PayrollAdjustmentEnquiryModel>> = [
  { id: 'technicianId', label: 'Technician' },
  { id: 'processDate', label: 'Date' },
  { id: 'type', label: 'Type' },
  { id: 'reason', label: 'Reason' },
  { id: 'amount', label: 'Amount' },
  { id: 'totalPayHoursSum', label: 'Total Pay Hours' },
  { id: 'payT', label: 'Pay T' },
  { id: 'payTH', label: 'Pay T/H' },
  { id: 'payD', label: 'Pay D' },
  { id: 'payDH', label: 'Pay D/H' }
];

export const PayrollEnquiryTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { enquiryGroups, amountOfRows, amountOfSelectedRows } = props;
  
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof PayrollAdjustmentEnquiryModel) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };
  
  const handleSelectAllClick = () => {
    enquiryGroups.forEach(group => group.selectAllItems());
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <EnhancedTableHead<PayrollAdjustmentEnquiryModel>
          headCells={headCells}
          numSelected={amountOfSelectedRows}
          rowCount={amountOfRows}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        {sort<IPayrollEnquiryGroup>(enquiryGroups, 'asc', 'team').map((group, index) => (
          !isEmpty(group.filteredEnquiries) &&
          <PayrollEnquiryGroup
            key={index}
            order={order}
            orderBy={orderBy}
            enquiryGroup={group}
          />
        ))}
      </Table>
    </Paper>
  );
});
