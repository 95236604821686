import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import React, { useState } from 'react';
import { IPayEnquiry } from '../domain/PayEnquiry';
import EnhancedTableHead, { HeadCell } from '../../components/enhanced-table/EnhancedTableHead';
import { observer } from 'mobx-react-lite';
import { SortOrder, sort } from '@utils';
import { useStyles } from './table.style';
import { IPayEnquiryGroup } from 'pay-enquiry/domain/PayEnquiryGroup';
import { PayEnquiryGroup } from './PayEnquiryGroup';

interface Props {
  payEnquiryGroups: IPayEnquiryGroup[];
  amountOfRows: number;
  amountOfSelectedRows: number;
}

const headCells: Array<HeadCell<IPayEnquiry>> = [
  { id: 'technician', label: 'Technician' },
  { id: 'processedDate', label: 'Processed' },
  { id: 'norm', label: 'Norm' },
  { id: 'th', label: 'T/H' },
  { id: 'doub', label: 'Doub' },
  { id: 'dh', label: 'D/H' },
  { id: 'sl', label: 'SL' },
  { id: 'al', label: 'AL' },
  { id: 'lsl', label: 'LSL' },
  { id: 'publicHolidays', label: <>Public&#10;Holidays</> },
  { id: 'carersLeave', label: <>Carers&#10;Leave</> },
  { id: 'leaveWoPay', label: <>Leave&#10;W/O Pay</> },
  { id: 'workersComp', label: <>Workers&#10;Comp</> },
  { id: 'mealAllowance', label: <>Meal&#10;Allowance</> },
  { id: 'callOutAllowance', label: <>Call Out&#10;Allowance</> },
  { id: 'phCallOutAllowance', label: <>PH Call Out&#10;Allowance</> },
  { id: 'fdo', label: <>FDO</> },
  { id: 'mdo', label: <>MDO</> },
  { id: 'anniversaryLeave', label: <>Anniversary&#10;Leave</> },
  { id: 'total', label: 'Total' }
];

export const PayEnquiryTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { payEnquiryGroups, amountOfRows, amountOfSelectedRows } = props;

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof IPayEnquiry) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  const handleSelectAllClick = () => {
    payEnquiryGroups.forEach(group => group.selectAllItems());
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <EnhancedTableHead<IPayEnquiry>
          cellClassName={classes.customCell}
          headCells={headCells}
          numSelected={amountOfSelectedRows}
          rowCount={amountOfRows}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        {sort<IPayEnquiryGroup>(payEnquiryGroups, 'asc', 'team').map((group, index) => (
          <PayEnquiryGroup
            key={index}
            order={order}
            orderBy={orderBy}
            payEnquiryGroup={group}
          />
        ))}
      </Table>
    </Paper>
  );
});
