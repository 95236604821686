import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { PayrollAdjustmentEnquiryModel } from '../domain/PayrollAdjustmentEnquiry';
import { formatDate } from '@utils';
import { Checkbox } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react';
import { useStyles } from './row.style';

interface Props {
  enquiry: PayrollAdjustmentEnquiryModel;
}

export const PayrollEnquiryRow: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { enquiry } = props;

  return (
    <TableRow 
      onClick={() => enquiry.selectItem()}
      className={classes.row}
      selected={enquiry.selected}
      aria-checked={enquiry.selected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={enquiry.selected}
          inputProps={{ 'aria-labelledby': 'labelId' }}
        />
      </TableCell>
      <TableCell align="left"> {enquiry.technician} </TableCell>
      <TableCell align="left"> {enquiry.date && formatDate(parseISO(enquiry.date))} </TableCell>
      <TableCell align="left"> {enquiry.type} </TableCell>
      <TableCell align="left"> {enquiry.reason} </TableCell>
      <TableCell align="left"> {enquiry.amount} </TableCell>
      <TableCell align="left"> {enquiry.totalPayHoursSum} </TableCell>
      <TableCell align="left"> {enquiry.payT} </TableCell>
      <TableCell align="left"> {enquiry.payTH} </TableCell>
      <TableCell align="left"> {enquiry.payD} </TableCell>
      <TableCell align="left"> {enquiry.payDH} </TableCell>
    </TableRow>
  );
});
